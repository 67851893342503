import React from 'react'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import SEO from './SEO'

const ParseSEOData = (props) => {
  const { data } = props
  let title = null
  let description = null
  let image = null
  const docTitle = get(data, 'title')
  const metaTitle = get(data, 'meta_title')
  const metaDescription = get(data, 'meta_description')
  const shareImage = get(data, 'share_image')
  const meta = get(data, '_meta')

  if(isArray(metaTitle)){
    title = get(metaTitle, '[0].text')
  }else{
    title = get(docTitle, '[0].text')
  }

  if(isArray(metaDescription)){
    description = get(metaDescription, '[0].text')
  }

  if(isArray(shareImage)){
    image = shareImage;
  }


  return(
    <SEO title={title} description={description} image={image} meta={meta} />
  )
}

export default ParseSEOData