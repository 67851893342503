import React from 'react'
import get from 'lodash/get'
import { RichText, Date } from 'prismic-reactjs'
import { linkResolver } from '../prismic/linkResolver'
import { Link } from "gatsby"

const ReviewComponent = (props) => {
  const {data} = props
  const title = get(data,'review.title[0].text')
  const author = get(data,'review.author[0].text')
  const date = Date(get(data,'review.date'))
  const text = RichText.render(get(data,'review.review'), linkResolver)

  return(
    <div className="review paragraph-max-width">
      {/* {title &&
        <h5 className="lh--m mb--xs">
          {title}
        </h5>
      } */}
      {text &&
      <div className="co--light lh--m mb--m">
        {text}
      </div>
      }
      {author &&
        <p className="mb--xs">{author}</p>
      }
      {data &&
        <p className="fs--xs tt--u co--light">{new Intl.DateTimeFormat('da-DK').format(date)}</p>
      }
    </div>
  )
}

export default ReviewComponent