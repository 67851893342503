import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import Slices from '../components/Slices'
import ParseSEOData from '../components/ParseSEOData'
export const query = graphql`
  query HomepageQuery($lang: String) {
    prismic {
      allHomepages(lang: $lang) {
        edges {
          node {
            title
            meta_description
            meta_title
            share_image
            _meta {
              type
              uid
              lang
            }
            body {
              ... on PRISMIC_HomepageBodyHero {
                type
                label
                primary {
                  heading
                  image
                  cta_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Samples {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Shop {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Collection {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Case {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                  cta_text
                }
              }
              ... on PRISMIC_HomepageBodyReview {
                type
                label
                primary {
                  reviews_title
                }
                fields {
                  review {
                    ... on PRISMIC_Review {
                      title
                      author
                      date
                      review
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyCollections {
                type
                label
              }
              ... on PRISMIC_HomepageBodyIntro {
                type
                label
                primary {
                  intro_cta {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      title
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Samples {
                      title
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Shop {
                      title
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Collection {
                      title
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Case {
                      title
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                  intro_text
                  intro_title
                }
                fields {
                  feature_description
                  feature_icon
                  feature_title
                  feature_link {
                    _linkType
                  }
                }
              }
              ... on PRISMIC_HomepageBodyFeature {
                type
                label
                primary {
                  feature_title
                  feature_text
                  feature_image
                  feature_heading
                  feature_imageSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                  feature_cta_link {
                    ... on PRISMIC_Homepage {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Samples {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Shop {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Collection {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Case {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                  feature_cta_text
                }
              }
            }
          }
        }
      }
    }
  }
`


const Homepage = ({ data }) => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  const lang = doc.node._meta.lang
  if (!doc) return null

  return (
    <>
      <ParseSEOData data={doc.node} />
      <Slices allSlices={doc.node.body} uid={doc.node._meta.uid} lang={lang} />
    </>
  )
}

Homepage.query = query

export default Homepage