import React, { useState } from "react"
import AspectRatio from "../components/AspectRatio"
import Img from "../components/CustomImg"
import InternalLink from "../components/InternalLink"
import TeaserTextOverlay from "./TeaserTextOverlay"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const CollectionTeaser = props => {
  const {
    title,
    heading,
    prismicImage,
    detailPrismicImage,
    price,
    meta,
  } = props
  const t = getTranslations(meta.lang)
  const [detailImgVisible, setDetailImgVisible] = useState(false)
  
  return (
    <div className="teaser">
      <InternalLink uid={meta.uid} type={meta.type} lang={meta.lang} title={title}>
        <div
          className="teaser-image-wrap mb--s"
          onMouseEnter={() => {
            setDetailImgVisible(true)
          }}
          onMouseLeave={() => {
            setDetailImgVisible(false)
          }}
        >
          <AspectRatio ratio="portrait">
            <Img prismicImage={prismicImage} />
            <CSSTransition
              in={detailImgVisible}
              timeout={500}
              classNames="teaser-img"
              unmountOnExit
            >
              <Img prismicImage={detailPrismicImage} />
            </CSSTransition>
          </AspectRatio>
          <TeaserTextOverlay heading={heading} cta={`${t.collections.explore} ${title}`} />
        </div>
        {title && (
          <>
            <h3 className="fs--m mb--xs">{title}</h3>
            <p className="co--light">{t.collections.from} DKK {price}</p>
          </>
        )}
      </InternalLink>
    </div>
  )
};

export default CollectionTeaser
