import React from "react"
import { Helmet } from "react-helmet"
import Button from "../components/Button"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import InternalLink from "./InternalLink"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const FeatureContent = props => {
  const { data } = props
  const { lang } = data

  const t = getTranslations(lang)

  const [showMoreImages, setShowMoreImages] = React.useState(false)
  const [showTimes, setShowTimes] = React.useState(true)
  const [showForm, setShowForm] = React.useState(false)

  const moreImagesClick = () => {
    setShowMoreImages(true)
  }

  const timesClick = () => {
    setShowTimes(true)
  }

  const timesRemoveClick = () => {
    setShowTimes(false)
  }

  const formClick = () => {
    setShowForm(true)
  }

  return (
    <div>
      <Helmet>
        <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
      </Helmet>
      <header className="mb--xxl">
        <div className="samples-header-inner">
          <h1 className="fs--xl lh--s">
            {RichText.render(data.data.primary.consultancy_heading)}
          </h1>
          <div
            className="co--light lh--m mb--l article consultancy-text"
            style={{ marginTop: "20px" }}
          >
            {RichText.render(data.data.primary.consultancy_text)}
            <br />
            {!showForm && (
              <div>
                <Link to={`${(!lang || lang === defaultLang) ? '' : `/${lang}`}/facetime`} style={{ textDecoration: "none" }}>
                  <Button
                    classList="button white full-width mb--m mr--s"
                    icon="chevron-right"
                    text={t.consultancy.facetime}
                  />
                </Link>
                <Link to={`${(!lang || lang === defaultLang) ? '' : `/${lang}`}/showroom`} style={{ textDecoration: "none" }}>
                  <Button
                    classList="button white full-width mb--m mr--s"
                    icon="chevron-right"
                    text={t.consultancy.showroom}
                  />
                </Link>
                <div>
                  <span onClick={() => formClick()}>
                    <Button
                      classList="button white full-width mb--m mr--s"
                      icon="chevron-right"
                      text={t.consultancy.email_advice}
                    />
                  </span>

                  <Link to={`${(!lang || lang === defaultLang) ? '/kontrakt' : `/${lang}/erhverv`}`} style={{ textDecoration: "none" }}>
                    <Button
                      classList="button white full-width"
                      icon="chevron-right"
                      text={t.consultancy.project}
                    />
                  </Link>
                </div>
                <div style={{ marginTop: 20 }}>
                  {t.consultancy.ready_to_order}{" "}
                  <InternalLink uid="shop" type="shop" title="Webshop" lang={lang}>
                    webshop
                  </InternalLink>{" "}
                  {t.consultancy.send_info}{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    span
                    onClick={() => formClick()}
                  >
                    {t.consultancy.here}
                  </span>
                  .
                </div>
              </div>
            )}
          </div>

          {showForm && (
            <iframe
              className="airtable-embed airtable-dynamic-height"
              src="https://airtable.com/embed/shr0aSque00B15002?prefill_Guidancy%20type=Email"
              frameBorder="0"
              width="100%"
              height="1700"
              className="guidance-iframe"
            ></iframe>
          )}
        </div>
      </header>

      <div className="slice even">
        <div className="wrapper">
          <div className="grid">
            <div className="text-col article">
              {RichText.render(data.data.primary.contact_info)}
            </div>
            <div className="image-col">
              <picture>
                <img src="https://images.prismic.io/anddrape/351ff51b-26ec-4fda-b59a-9e28572d9a74_IMG_4462.jpg?auto=compress,format" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Feature = props => {
  const { title, wrapLink } = props

  return (
    <div>
      <FeatureContent data={props} />
    </div>
  )
}

export default Feature
