import React from 'react'
import get from 'lodash/get'
import Wrapper from '../components/Wrapper'
import Grid from '../components/Grid'
import Img from '../components/CustomImg'
import AspectRatio from '../components/AspectRatio'

const Gallery = (props) => {
  const {data} = props
  const primary = get(data, 'primary')
  const imageFirst = get(primary, 'image_first')
  const imageFirstUrl = get(imageFirst, 'url')
  const imageFirstAspectRatio = get(primary, 'image_firstSharp.childImageSharp.sizes.aspectRatio')
  const imageFirstOrientation = imageFirstAspectRatio > 1 ? 'landscape' : 'portrait'
  const imageLast = get(primary, 'image_last')
  const imageLastUrl = get(imageLast, 'url')
  const imageLastAspectRatio = get(primary, 'image_lastSharp.childImageSharp.sizes.aspectRatio')
  const imageLastOrientation = imageLastAspectRatio > 1 ? 'landscape' : 'portrait'
  const count = imageFirstUrl && imageLastUrl ? 2 : 1
  return(
    <div className="gallery" data-orientation={`${imageFirstOrientation}-${imageLastOrientation}`} data-count={count}>
      <Wrapper>
        <Grid>
          {imageFirstUrl &&
            <div data-orientation={imageFirstOrientation}>
              <AspectRatio ratio={imageFirstOrientation}>
                <Img prismicImage={imageFirst} />
              </AspectRatio>
            </div>
          }
          {imageLastUrl &&
            <div data-orientation={imageLastOrientation}>
              <AspectRatio ratio={imageLastOrientation}>
                <Img prismicImage={imageLast} />
              </AspectRatio>
            </div>
          }
        </Grid>
      </Wrapper>
    </div>
  )
}

export default Gallery