import React from "react"
import get from "lodash/get"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { linkResolver } from "../prismic/linkResolver"

const SEO = ({ title, description, image, meta }) => (
  <StaticQuery
    query={query}
    render={data => {
      const layoutData = data.prismic.allLayouts.edges.slice(0, 1).pop().node
      const siteUrl = "https://www.anddrape.com"
      const slug = linkResolver(meta)
      const titleTemplate = get(layoutData, "meta_title_template[0].text")

      if (!description) {
        description = get(layoutData, "default_meta_description[0].text")
      }
      if (!image) {
        image = get(layoutData, "default_share_image")
      }

      return (
        <>
          <Helmet title={title} titleTemplate={`%s | ${titleTemplate}`}>
            <meta name="description" content={description} />
            <meta name="image" content={image} />
            <meta property="og:url" content={`${siteUrl}${slug}`} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image.url} />
            <meta property="og:image:width" content={image.dimensions.width} />
            <meta
              property="og:image:height"
              content={image.dimensions.height}
            />
            <meta property="og:image:alt" content={image.dimensions.alt} />
            <meta property="og:locale" content="da_DK" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image.url} />
            <link rel="dns-prefetch" href="//use.typekit.net" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossorigin
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap"
              rel="stylesheet"
            />
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO

const query = graphql`
  query {
    prismic {
      allLayouts {
        edges {
          node {
            default_meta_description
            default_share_image
            meta_title_template
          }
        }
      }
    }
  }
`
