import React from "react"
import { RichText } from "prismic-reactjs"

const Iframe = ({ data, index }) => {
  const even = index % 2 == 0 ? "" : "even" //opposite logic but needed for design
  return (
    <div className={`slice ${even} mb--l`}>
      <div className="wrapper-with-padding ">
        <h2 className="fs--m">{RichText.asText(data.primary.embed_title)}</h2>
        <div className="co--light lh--m mb--s mb--m">
          {RichText.render(data.primary.embed_description)}
        </div>
        <div
          class="videoWrapper"
          dangerouslySetInnerHTML={{ __html: data.primary.embed_url.html }}
        />
      </div>
    </div>
  )
}

export default Iframe
