import React from "react"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import Carrousel from "./Carrousel"
import CollectionTeaser from "../components/CollectionTeaser"

const collectionsCarrouselQuery = graphql`
  query {
    prismic {
      allCollections(sortBy: rank_ASC) {
        edges {
          node {
            title
            heading
            from_price
            featured_image_portrait
            featured_detail_image
            _meta {
              uid
              type
              lang
            }
          }
        }
      }
    }
  }
`

const CollectionsCarrousel = props => {
  const { title, notIn, lang } = props
  let slides = []
  return (
    <div className="collections slice">
      <StaticQuery
        query={collectionsCarrouselQuery}
        render={data => {
          let collectionsData = data.prismic.allCollections.edges
          collectionsData = collectionsData.filter(collection => {
            return collection.node._meta.lang === lang
          })
          return collectionsData.map((collection, index) => {
            const node = get(collection, "node")
            const title = get(node, "title[0].text")
            const heading = get(node, "heading[0].text")
            const prismicImage = get(node, "featured_image_portrait")
            const detailPrismicImage = get(node, "featured_detail_image")
            const meta = get(node, "_meta")
            const uid = get(meta, "uid")
            const price = get(node, "from_price")
            if (uid !== notIn) {
              slides.push(
                <CollectionTeaser
                  key={index}
                  title={title}
                  heading={heading}
                  prismicImage={prismicImage}
                  detailPrismicImage={detailPrismicImage}
                  meta={meta}
                  price={price}
                />
              )
            }
          })
        }}
      />
      <Carrousel title={title} slides={slides} />
    </div>
  )
}

CollectionsCarrousel.query = collectionsCarrouselQuery

export default CollectionsCarrousel
