import React from "react"
import Wrapper from "../components/Wrapper"
import RadioButton from "../components/RadioButton"
// import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "gatsby"
import Button from "../components/Button"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const ContactForm = ({ lang }) => {
  const t = getTranslations(lang)
  
  return (
    <div className="article contact-form">
      <Wrapper>
        <div className="paragraph-max-width">
          <h2>{t.contact_form.title}</h2>
          <div className="contact-form-description">
            <p>
              {t.contact_form.desc}
            </p>
            <Link to="/kalender">
              <Button
                classList="consultancy-button primary"
                icon="chevron-right"
                text={t.contact_form.facetime_button}
              />
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Link to={`${lang === defaultLang || !lang ? "/showroom" : lang + "/showroom"}`}>
              <Button
                classList="consultancy-button primary"
                icon="chevron-right"
                text={t.contact_form.showroom_button}
              />
            </Link>
            <br />
            {t.contact_form.via_form}
          </div>
          {/* <form name="Contact Form" method="POST" data-netlify="true" data-netlify-recaptcha='true'> */}
          <form
            name="Contact Form"
            method="POST"
            data-netlify="true"
            action="/tak-for-din-besked"
          >
            <input type="hidden" name="form-name" value="Contact Form" />
            <div className="grid">
              <div>
                <label>{t.contact_form.name}*</label>
                <input
                  type="text"
                  name="fornavn"
                  placeholder={t.contact_form.name_placeholder}
                  required
                />
              </div>
              <div>
                <label>{t.contact_form.surname}*</label>
                <input
                  type="text"
                  name="efternavn"
                  placeholder={t.contact_form.surname_placeholder}
                  required
                />
              </div>
            </div>
            <div className="grid">
              <div>
                <label>Email*</label>
                <input
                  type="email"
                  name="email"
                  placeholder={t.contact_form.email_placeholder}
                  required
                />
              </div>
              <div>
                <label>{t.contact_form.phone}</label>
                <input
                  type="tel"
                  name="tel"
                  placeholder={t.contact_form.phone_placeholder}
                />
              </div>
            </div>
            <div>
              <label>{t.contact_form.subject}*</label>
              <input
                type="text"
                name="emne"
                placeholder={t.contact_form.subject_placeholder}
                required
              />
            </div>
            <div>
              <label>{t.contact_form.message}*</label>
              <textarea
                name="message"
                rows="5"
                placeholder={t.contact_form.message_placeholder}
                required
              />
            </div>

            <button type="submit">{t.contact_form.submit}</button>
          </form>
        </div>
      </Wrapper>
    </div>
  )
}

export default ContactForm
