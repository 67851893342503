import React from 'react'
import get from 'lodash/get'
import Wrapper from '../components/Wrapper'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../prismic/linkResolver'

const Article = (props) => {
  const {data} = props
  const richText = RichText.render(get(data,'fields[0].rich_text'), linkResolver)
  return(
    <div className="article">
      <Wrapper>
        <div className="paragraph-max-width">
          {richText}
        </div>
      </Wrapper>
    </div>
  )
}

export default Article