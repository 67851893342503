import React from 'react'
import Carrousel from './Carrousel'
import ReviewComponent from './Review'

const Reviews = (props) => {
  const {title, reviews} = props
  let slides = []
  reviews.forEach((review, index)=>{
    slides.push(<ReviewComponent key={index} data={review} />)
  })

  return (
    <div className="collections slice">
      <Carrousel title={title} reviews="true" slides={slides} />
    </div>
  )
}

export default Reviews