import React from "react"
import { RichText } from "prismic-reactjs"
import { InlineWidget } from "react-calendly"

const BookShowroom = ({ data }) => {
  return (
    <>
      <div>
        <header className="mb--xxl">
          <div className="samples-header-inner">
            <h1 className="fs--xl lh--s">
              {RichText.render(data.primary?.title1)}
            </h1>
            <div
              className="co--light lh--m article consultancy-text"
              style={{ marginTop: "20px" }}
            >
              {RichText.render(data.primary?.description)}
            </div>
          </div>
          <InlineWidget
            url={`https://calendly.com/drape/showroom-tilmeld-drop-in-her?hide_event_type_details=1`}
          />
        </header>
      </div>
    </>
  )
}

export default BookShowroom
