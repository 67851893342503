import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../prismic/linkResolver"
import get from "lodash/get"
import Grid from "./Grid"
import Wrapper from "./Wrapper"
import SewingMachineSVG from "../images/sewingmachine.inline.svg"
import ToolsSVG from "../images/tools.inline.svg"
import PaletteSVG from "../images/palette.inline.svg"
import CurtainSVG from "../images/curtain.inline.svg"
import InternalLink from "../components/InternalLink"
import Button from "../components/Button"

const Intro = props => {
  const { data } = props
  const primary = get(data, "primary")
  const heading = get(data, "primary.intro_title[0].text")
  const cta = get(data, "primary.intro_cta")
  const ctaMeta = get(cta, "_meta")
  const ctaTitle = get(cta, "title[0].text")
  let text = get(data, "primary.intro_text")
  if (text) {
    text = RichText.render(text, linkResolver)
  }
  const fields = get(data, "fields")
  return (
    <div className="slice intro">
      <Wrapper>
        <Grid>
          <div>
            <div className="paragraph-max-width">
              <h2 className="fs--m lh--s mb--xs">{heading}</h2>
              <div className="co--light lh--m">{text}</div>
              {cta && (
                <InternalLink
                  title={ctaTitle}
                  uid={ctaMeta.uid}
                  lang={ctaMeta.lang}
                  type={ctaMeta.type}
                  classList="wrap-link"
                >
                  <Button icon="chevron-right" text={ctaTitle} />
                </InternalLink>
              )}
            </div>
          </div>
          <div className="features">
            <Grid>
              {fields &&
                fields.map((field, index) => {
                  const fieldTitle = get(field, "feature_title[0].text")
                  const featureIcon = get(field, "feature_icon")
                  let iconSVG = null

                  switch (featureIcon) {
                    case "Sewing Machine":
                      iconSVG = <SewingMachineSVG />
                      break
                    case "Tools":
                      iconSVG = <ToolsSVG />
                      break
                    case "Palette":
                      iconSVG = <PaletteSVG />
                      break
                    case "Curtain":
                      iconSVG = <CurtainSVG />
                      break
                    default:
                      break
                  }
                  let fieldText = get(field, "feature_description")
                  if (fieldText) {
                    fieldText = RichText.render(fieldText, linkResolver)
                  }

                  return (
                    <div key={index}>
                      <div className="icon mb--xs">{iconSVG}</div>
                      <h3 className="lh--m">{fieldTitle}</h3>
                      <div className="co--light fs--s lh--m">{fieldText}</div>
                    </div>
                  )
                })}
            </Grid>
          </div>
        </Grid>
      </Wrapper>
    </div>
  )
}

export default Intro
