import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import get from "lodash/get"
import Wrapper from "./Wrapper"
import Feature from "./Feature"
import Consultancy from "./Consultancy"
import Facetime from "./Facetime"
import Showroom from "./Showroom"
import Iframe from "./Iframe"
import Gallery from "./Gallery"
import Hero from "./Hero"
import Intro from "./Intro"
import Reviews from "./Reviews"
import CollectionsCarrousel from "./CollectionsCarrousel"
import Article from "./Article"
import ContactForm from "./ContactForm"
import { linkResolver } from "../prismic/linkResolver"
import getTranslations from "../translations"
import BookShowroom from "./BookShowroom"
import BookDesignMeeting from "./BookDesignMeeting"

const Slices = props => {
  const { allSlices, uid, lang} = props
  const t = getTranslations(lang)

  if (!Array.isArray(allSlices)) {
    return false
  }

  const slice = allSlices.map((s, index) => {
    switch (s.type) {
      case "review":
        let reviewsTitle = get(s, "primary.reviews_title[0].text")
        let reviews = get(s, "fields")
        return <Reviews key={index} title={reviewsTitle} reviews={reviews} />
        break
      case "collections":
        return <CollectionsCarrousel key={index} title={t.collections.all_collections} lang={lang} />
        break
      case "intro":
        return <Intro key={index} data={s} key={index} />
        break
      case "hero":
        let heroHeading = get(s, "primary.heading[0].text")
        let prismicImage = get(s, "primary.image")
        let heroCTALink = get(s, "primary.cta_link")
        let heroCTAText = get(s, "primary.cta_text")
        return (
          <Hero
            key={index}
            heading={heroHeading}
            prismicImage={prismicImage}
            heroCTALink={heroCTALink}
            heroCTAText={heroCTAText}
            lang={lang}
          />
        )
        break
      case "article":
        return <Article key={index} data={s} />
        break
      case "gallery":
        return <Gallery key={index} data={s} />
        break
      case "contact_form":
        return <ContactForm key={index} lang={lang} />
        break
      case "consultancy":
        return <Consultancy key={index} data={s} lang={lang} />
        break
      case "facetime_calendar":
        return <Facetime key={index} data={s} />
        break
      case "book_shoowroom":
        return <BookShowroom key={index} data={s} />
        break
      case "book_designm_de":
        return <BookDesignMeeting key={index} data={s} />
        break
      case "showroom":
        return <Showroom key={index} data={s} uid={uid} />
        break
      case "iframe":
        return <Iframe key={index} data={s} index={index} />
        break
      case "feature":
        const primary = get(s, "primary")
        const title = get(primary, "feature_title[0].text")
        const heading = get(primary, "feature_heading[0].text")
        const featurePrismicImage = get(primary, "feature_image")
        let text = get(primary, "feature_text")
        const featureCTAText = get(primary, "feature_cta_text[0].text")
        const featureLinkMeta = get(primary, "feature_cta_link._meta")
        if (text) {
          text = RichText.render(text, linkResolver)
        }
        console.log(featurePrismicImage)

        return (
          <Feature
            key={index}
            title={title}
            heading={heading}
            text={text}
            prismicImage={featurePrismicImage}
            wrapLink={featureLinkMeta}
            wrapLinkText={featureCTAText}
          />
        )
        break
      default:
        return (
          <Wrapper key={index}>
            <h3 key={index}>{s.type}</h3>
          </Wrapper>
        )
    }
  })

  return <>{slice}</>
}

Slices.propTypes = {
  allSlices: PropTypes.array.isRequired,
}

export default Slices
