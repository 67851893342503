import React from 'react'
import Slider from 'react-slick'
import Wrapper from './Wrapper'
import ChevronLeftSVG from '../images/chevron-left.inline.svg'
import ChevronRightSVG from '../images/chevron-right.inline.svg'

const Carrousel = (props) => {
  const {title, slides, reviews} = props

  const Arrow = (props) => {
    const { className, style, onClick, children } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        {children}
        </div>
    );
  }

  let slidesToShow = 2.5
  let fade = false
  let infinite = false
  let responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        arrows: false,
        nextArrow: <Arrow><ChevronRightSVG /></Arrow>,
        prevArrow: <Arrow><ChevronLeftSVG /></Arrow>,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.5,
        arrows: false,
        nextArrow: <Arrow><ChevronRightSVG /></Arrow>,
        prevArrow: <Arrow><ChevronLeftSVG /></Arrow>,
      }
    },
    {
      breakpoint: 415,
      settings: {
        slidesToShow: 1.125,
        arrows: false,
        nextArrow: '',
        prevArrow: '',
      }
    }
  ]

  if(reviews){
    slidesToShow = 1
    fade = true
    infinite = true
    responsive = [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false,
          nextArrow: <Arrow><ChevronRightSVG /></Arrow>,
          prevArrow: <Arrow><ChevronLeftSVG /></Arrow>,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          nextArrow: <Arrow><ChevronRightSVG /></Arrow>,
          prevArrow: <Arrow><ChevronLeftSVG /></Arrow>,
        }
      },
      {
        breakpoint: 415,
        settings: {
          slidesToShow: 1,
          arrows: false,
          nextArrow: '',
          prevArrow: '',
        }
      }
    ]
  }

  const settings = {
    dots: true,
    infinite: infinite,
    speed: 500,
    slidesToShow: slidesToShow,
    fade: fade,
    adaptiveHeight: true,
    draggable: false,
    nextArrow: <Arrow><ChevronRightSVG /></Arrow>,
    prevArrow: <Arrow><ChevronLeftSVG /></Arrow>,
    customPaging: function(i) {
      return (
        <div className="pagination-dot"></div>
      );
    },
  }

  return(
    <div className={`carrousel ${reviews && 'carrousel-reviews'}`}>
      {title &&
        <header className="mb--l">
          <Wrapper>
            <h3 className="fs--m">{title}</h3>
          </Wrapper>
        </header>
      }
      {slides &&
        <Slider {...settings}>
          {slides.map((slide, index)=>{
            return slide
          })}
        </Slider>
      }
    </div>
  )
}

export default Carrousel