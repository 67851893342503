import React from "react"
import Grid from "../components/Grid"
import Wrapper from "../components/Wrapper"
import Img from "../components/CustomImg"
import Button from "../components/Button"
import AspectRatio from "../components/AspectRatio"
import InternalLink from "../components/InternalLink"

const FeatureContent = props => {
  const { data } = props
  return (
    <Grid>
      <div className="text-col">
        <div className="paragraph-max-width">
          {data.title && data.heading && (
            <>
              <h2 className="fs--s mb--xs tt--u co--light">{data.title}</h2>
              <h3 className="fs--m mb--s lh--s">{data.heading}</h3>
            </>
          )}
          {data.text && (
            <div className="co--light lh--m mb--l article">{data.text}</div>
          )}
          {data.wrapLink && (
            <Button
              icon="chevron-right"
              text={data.wrapLinkText ? data.wrapLinkText : "udforsk"}
            />
          )}
        </div>
      </div>
      {data.imgSrc ||
        (data.prismicImage && (
          <div className="image-col">
            <AspectRatio ratio="portrait">
              {data.prismicImage ? (
                <Img prismicImage={data.prismicImage} />
              ) : (
                <Img src={data.imgSrc} />
              )}
            </AspectRatio>
          </div>
        ))}
    </Grid>
  )
}

const Feature = props => {
  const { title, wrapLink } = props

  return (
    <div className="slice feature">
      <Wrapper>
        {wrapLink ? (
          <InternalLink
            title={title}
            uid={wrapLink.uid}
            type={wrapLink.type}
            lang={wrapLink.lang}
            classList="wrap-link"
          >
            <FeatureContent data={props} />
          </InternalLink>
        ) : (
          <FeatureContent data={props} />
        )}
      </Wrapper>
    </div>
  )
}

export default Feature
